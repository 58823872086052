import React, { useState, useEffect, useRef, MouseEventHandler } from 'react';
import RangeSlider2 from './RangeSlider2';
import Collapsible from './Collapsible';
import DiamondFilterItem from './DiamondFilterItem';
import CheckBoxes from './CheckBoxes';
import SelectedFilters from './SelectedFilters';
import { hover } from '@testing-library/user-event/dist/hover';

type FilterProps = {
    setProducts: React.Dispatch<React.SetStateAction<any[]>>,
    sortingByAsc: [string, boolean],
    setUrl: React.Dispatch<React.SetStateAction<string>>,
    viewPort: string
};


const shapeValues = [
    "ROUND",
    "PEAR",
    "EMERALD",
    "PRINCESS",
    "HEART",
    "CUSHION MODIFIED",
    "RADIANT",
    "MARQUISE",
    "OVAL",
    "CUSHION BRILLIANT",
    "EUROPEAN CUT",
    "SQUARE EMERALD",
    "TRAPEZOID",
    "KITE",
    "TRIANGULAR",
    "ASSCHER",
    "OTHER",
    "SQUARE RADIANT",
    "CUSHION",
    "SHIELD",
    "BAGUETTE",
    "ROSE",
    "BRIOLETTE",
    "TAPERED BAGUETTE",
    "OLD MINER",
    "TRILLIANT",
    "HEXAGONAL",
    "HALF MOON",
    "SQUARE",
    "NONAGONAL",
    "PENTAGONAL",
    "OCTAGONAL",
    "HEPTAGONAL",
    "BULLET",
    "LOZENGE",
    "STAR",
    "PEAR MODIFIED BRILLIANT",
    "RHOMBOID",
    "RECTANGULAR"
]
const initialCarat = [20, 2000] // * 100
const initialPrice = [370, 30000]
const initialLWRatio = [100, 300] // * 100
const initialTable = [4000, 9000] // * 100
const initialDepth = [4000, 9500] // * 100
const cutValueShortStrings = [
    /*    "PR,P", */
    "FR,F",
    "GD",
    "VG",
    "EX",
    "ID",
    "EIGHTX"
]

const cutValueStrings = [
    /*  "Poor", */
    "Fair",
    "Good",
    "Very Good",
    "Excellent",
    "Ideal",
    "Super Ideal"
]

const colorValues = ["J", "I", "H", "G", "F", "E", "D"]

const clarityValues = ["SI2", "VS2", "VS1", "VVS2", "VVS1", "IF", "FL"]

const collectionValues = ["Flawless Collection", "Carbon Capture", "100% Renewable Energy", "Sustainably Rated", "Truly Brilliant"]

const fluorescenceStrings = ["Very Strong", "Strong", "Medium", "Faint", "Slight", "Very Slight", "None"]
const fluorescenceShortStrings = ["VST", "STG,STN", "MED", "FNT", "SLT", "VSL", "NON"]

const polishStrings = [/* "Poor", "Fair", */ "Good", "Very Good", "Excellent", "Ideal", "Super Ideal"]
const polishShortStrings = [/* "PR,P", "FR,F", */ "GD", "VG", "EX", "ID", "EIGHTX"]

const symmetryStrings = [/* "Poor", "Fair", */ "Good", "Very Good", "Excellent", "Ideal", "Super Ideal"]
const symmetryShortStrings = [/* "PR,P", "FR,F", */ "GD", "VG", "EX", "ID", "EIGHTX"]


const reportValues = ["IGI", "GIA", "GCAL", "HRD"]

const featureValues = ["Hearts & Arrows"]

const dropDownItems = ["Most Popular", "Price (Low to High)", "Price (High to Low)", "Carat (Low to High)", "Carat (High to Low)", "Cut (Low to High)", "Cut (High to Low)", "Color (Low to High)", "Color (High to Low)", "Clarity (Low to High)", "Clarity (High to Low)"/* , "Shape (A to Z)", "Shape (Z to A)" */]

const Filter: React.FC<FilterProps> = ({ setProducts, sortingByAsc, setUrl, viewPort }) => {

    let FilterElement = useRef<HTMLDivElement>(null)
    let lastActionWasMultiFilterButtonClick = useRef<boolean>(false)

    const [caratMaxims, setCaratMaxims] = useState<number[]>(initialCarat);
    const [priceMaxims, setPriceMaxims] = useState<number[]>(initialPrice);
    const [lWMaxims, setLWMaxims] = useState<number[]>(initialLWRatio);
    const [tableMaxims, setTableMaxims] = useState<number[]>(initialTable);
    const [depthMaxims, setDepthMaxims] = useState<number[]>(initialDepth);

    const [caratsMinCurrentValue, setCaratsCurrentMinVal] = useState(initialCarat[0]);
    const [caratsMaxCurrentValue, setCaratsCurrentMaxVal] = useState(initialCarat[1]);

    const [priceMinCurrentValue, setPriceCurrentMinVal] = useState(initialPrice[0]);
    const [priceMaxCurrentValue, setPriceCurrentMaxVal] = useState(initialPrice[1]);

    const [cutMinCurrentValue, setCutCurrentMinVal] = useState(0);
    const [cutMaxCurrentValue, setCutCurrentMaxVal] = useState(cutValueShortStrings.length);

    const [colorMinCurrentValue, setColorCurrentMinVal] = useState(0);
    const [colorMaxCurrentValue, setColorCurrentMaxVal] = useState(colorValues.length);

    const [clarityMinCurrentValue, setClarityCurrentMinVal] = useState(0);
    const [clarityMaxCurrentValue, setClarityCurrentMaxVal] = useState(clarityValues.length);

    const [lWRatioMinCurrentValue, setLWRatioCurrentMinVal] = useState(initialLWRatio[0]);
    const [lWRatioMaxCurrentValue, setLWRatioCurrentMaxVal] = useState(initialLWRatio[1]);

    const [fluorescenceMinCurrentValue, setFluorescenceCurrentMinVal] = useState(initialLWRatio[1]);
    const [fluorescenceMaxCurrentValue, setFluorescenceCurrentMaxVal] = useState(initialLWRatio[1]);

    const [polishMinCurrentValue, setPolishCurrentMinVal] = useState(0);
    const [polishMaxCurrentValue, setPolishCurrentMaxVal] = useState(polishShortStrings.length);

    const [symmetryMinCurrentValue, setSymmetryCurrentMinVal] = useState(0);
    const [symmetryMaxCurrentValue, setSymmetryCurrentMaxVal] = useState(symmetryShortStrings.length);

    const [tableMinCurrentValue, setTableCurrentMinVal] = useState(initialTable[0]);
    const [tableMaxCurrentValue, setTableCurrentMaxVal] = useState(initialTable[1]);

    const [depthMinCurrentValue, setDepthCurrentMinVal] = useState(initialDepth[0]);
    const [depthMaxCurrentValue, setDepthCurrentMaxVal] = useState(initialDepth[1]);

    const [selectedSpecialOption, setSpecialOption] = useState("")

    /* Filter */
    const [selectedNatural, setNatural] = useState<boolean>(true);
    const [selectedDiamondShapes, setDiamondShape] = useState<string[]>([]);
    const [selectedCarat, setCarat] = useState<number[]>(initialCarat);

    const [selectedPrice, setPrice] = useState<number[]>(initialPrice);
    const [selectedCut, setCut] = useState<number[]>([0, cutValueShortStrings.length]);
    const [selectedColor, setColor] = useState<number[]>([0, colorValues.length]);
    const [selectedClarity, setClarity] = useState<number[]>([0, clarityValues.length]);
    const [selectedLWRatio, setLWRatio] = useState<number[]>(initialLWRatio);
    const [selectedFluorescence, setFluorescence] = useState<number[]>([0, fluorescenceShortStrings.length]);
    const [selectedPolish, setPolish] = useState<number[]>([0, polishShortStrings.length]);
    const [selectedSymmetry, setSymmetry] = useState<number[]>([0, symmetryShortStrings.length]);
    const [selectedTable, setTable] = useState<number[]>(initialTable);
    const [selectedDepth, setDepth] = useState<number[]>(initialDepth);
    const [selectedReports, setReport] = useState<string[]>([]);
    const [selectedDelivery, setDelivery] = useState("");

    const [selectableDiamondShapes, setSelectableDiamondShapes] = useState<string[]>([])

    const [diamondImageUrls, setDiamondImageUrls] = useState<string[]>([])

    const handleSpecialOptionClick = (event: any) => {
        setSpecialOption(event.target.innerHTML)

        filterSetter(["reset-all", ""])

        switch (event.target.innerHTML) {
            case "Most Sparkle":
                //Cut: super ideal
                setCut([cutValueShortStrings.length - 1, cutValueShortStrings.length])
                setCutCurrentMinVal(cutValueShortStrings.length - 1)
                setCutCurrentMaxVal(cutValueShortStrings.length)
                //fluorescence strong - none
                setFluorescence([1, fluorescenceShortStrings.length])
                setFluorescenceCurrentMinVal(1)
                setFluorescenceCurrentMaxVal(fluorescenceShortStrings.length)
                break
            case "Best Balance":
                //Cut: Very Good - Super Ideal
                setCut([2, cutValueShortStrings.length])
                setCutCurrentMinVal(2)
                setCutCurrentMaxVal(cutValueShortStrings.length)
                //color: H-F
                setColor([2, colorValues.length - 2])
                setColorCurrentMinVal(2)
                setColorCurrentMaxVal(colorValues.length - 2)
                //clarity: SI1 - VS1
                setClarity([1, clarityValues.length - 4])
                setClarityCurrentMinVal(1)
                setClarityCurrentMaxVal(clarityValues.length - 4)
                //fluorescence strong - none
                setFluorescence([1, fluorescenceShortStrings.length])
                setFluorescenceCurrentMinVal(1)
                setFluorescenceCurrentMaxVal(fluorescenceShortStrings.length)
                break
            case "Top Quality":
                //Cut: Ideal - Super Ideal
                setCut([cutValueShortStrings.length - 2, cutValueShortStrings.length])
                setCutCurrentMinVal(cutValueShortStrings.length - 2)
                setCutCurrentMaxVal(cutValueShortStrings.length)
                //color: F-D
                setColor([colorValues.length - 3, colorValues.length])
                setColorCurrentMinVal(colorValues.length - 3)
                setColorCurrentMaxVal(colorValues.length)
                //clarity: VVS2 - FL
                setClarity([clarityValues.length - 4, clarityValues.length])
                setClarityCurrentMinVal(clarityValues.length - 4)
                setClarityCurrentMaxVal(clarityValues.length)
                //fluorescence Medium - none
                setFluorescence([2, fluorescenceShortStrings.length])
                setFluorescenceCurrentMinVal(2)
                setFluorescenceCurrentMaxVal(fluorescenceShortStrings.length)
                break
            default:
                console.error("ERROR")
                break
        }

        lastActionWasMultiFilterButtonClick.current = true
    }

    useEffect(() => {

        const el1: string[] = Array.from(document.querySelectorAll("tbody tr:first-child td.value>div>div:last-child>.vi-wpvs-option-wrap"))
            .map(el => el.getAttribute("data-attribute_value")?.toUpperCase())
            .filter((value): value is string => value !== null);

        if (el1.length > 0)
            setSelectableDiamondShapes(el1)

        const el2: string[] = Array.from(document.querySelectorAll("tbody tr:first-child td.value>div>div:last-child>.vi-wpvs-option-wrap>img"))
            .map(el => el.getAttribute("src"))
            .filter((value): value is string => value !== null);

        if (el2.length > 0)
            setDiamondImageUrls(el2)
    }, [])

    useEffect(() => {

        if (!lastActionWasMultiFilterButtonClick.current)
            setSpecialOption("")
        else
            lastActionWasMultiFilterButtonClick.current = false

        const apiStringPicker = (stringArray: string[], intArray: number[], category: string): string => {

            return intArray[0] === 0 && intArray[1] === stringArray.length ? "" : `&${category}=${stringArray.slice(intArray[0], intArray[1]).join(",")}`
        }

        const apIntPicker = (maxValues: number[], currentValues: number[], category: string): string => {

            return maxValues[0] === currentValues[0] && maxValues[1] === currentValues[1] ? "" : `&${category}=${currentValues[0] / 100}-${currentValues[1] / 100}`
        }

        const apiPricePicker = (maxValues: number[], currentValues: number[], category: string): string => {

            return maxValues[0] === currentValues[0] && maxValues[1] === currentValues[1] ? "" : `&${category}=${currentValues[0]}-${currentValues[1]}`
        }

        let url = 'https://everrings.com/wp-json/csv-api/v1/view-diamonds?limit=50'

        /* const shapes: string = selectedDiamondShapes.length === 0 ? "" : `&shape=${selectedDiamondShapes.join(",")}` */

        const shapesFilter = selectableDiamondShapes.length > 0 ? (selectedDiamondShapes.length > 0 ? `&shape=${selectedDiamondShapes.join(",")}` : `&shape=${selectableDiamondShapes.join(",")}`) : ""

        const labGrownFilter: string = selectedNatural ? "&labgrown=false" : "&labgrown=true"

        const caratsFilter = apIntPicker(caratMaxims, selectedCarat, "carats")
        const priceFilter = apiPricePicker(priceMaxims, selectedPrice, "Price")

        const cutFilter = apiStringPicker(cutValueShortStrings, selectedCut, "Cut")
        const colorFilter = apiStringPicker(colorValues, selectedColor, "Color")
        const clarityFilter = apiStringPicker(clarityValues, selectedClarity, "Clarity")
        const lWRatioFilter = apIntPicker(lWMaxims, selectedLWRatio, "LWRatio")
        const fluorescenceFilter = apiStringPicker(fluorescenceShortStrings, selectedFluorescence, "Fluorescence")
        const polishFilter = apiStringPicker(polishShortStrings, selectedPolish, "Polish")
        const symmetryFilter = apiStringPicker(symmetryShortStrings, selectedSymmetry, "Symmetry")
        const tableFilter = apIntPicker(tableMaxims, selectedTable, "Table")
        const depthFilter = apIntPicker(depthMaxims, selectedDepth, "Depth")
        const reportFilter = selectedReports.length !== 0 ? `&report=${selectedReports.join(",")}` : ""

        const sortFilter = sortingByAsc[0] !== dropDownItems[0] ? `&sort_by=${sortingByAsc[0].toLowerCase()}&sort_order=${sortingByAsc[1] ? "asc" : "desc"}` : ""

        url = url.concat(shapesFilter, labGrownFilter, caratsFilter, priceFilter, cutFilter, colorFilter, clarityFilter, lWRatioFilter, fluorescenceFilter, polishFilter, symmetryFilter, tableFilter, depthFilter, reportFilter, sortFilter)

        console.log(url)

        setUrl(url)
    }, [selectedNatural, selectableDiamondShapes, selectedDiamondShapes, selectedCarat, selectedPrice, selectedCut, selectedColor, selectedClarity, selectedLWRatio, selectedFluorescence, selectedPolish, selectedSymmetry, selectedTable, selectedDepth, selectedReports, sortingByAsc]);

    const filterReset = (event: any) => {
        const element = event.currentTarget
        const attributes = element.getAttribute("data-value").split(" ")
        filterSetter(attributes)
        setSpecialOption("")
    }

    const filterSetter = (value: string[]) => {
        switch (value[0]) {
            case "shape":
                setDiamondShape(selectedDiamondShapes.filter(item => item !== value[1]))
                break
            case "carat":
                setCarat(caratMaxims)
                setCaratsCurrentMinVal(initialCarat[0])
                setCaratsCurrentMaxVal(initialCarat[1])
                break
            case "price":
                setPrice(priceMaxims)
                setPriceCurrentMinVal(initialPrice[0])
                setPriceCurrentMaxVal(initialPrice[1])
                break
            case "cut":
                setCut([0, cutValueShortStrings.length])
                setCutCurrentMinVal(0)
                setCutCurrentMaxVal(cutValueShortStrings.length)
                break
            case "color":
                setColor([0, colorValues.length])
                setColorCurrentMinVal(0)
                setColorCurrentMaxVal(colorValues.length)
                break
            case "clarity":
                setClarity([0, clarityValues.length])
                setClarityCurrentMinVal(0)
                setClarityCurrentMaxVal(clarityValues.length)
                break
            case "fluorescence":
                setFluorescence([0, fluorescenceShortStrings.length])
                setFluorescenceCurrentMinVal(0)
                setFluorescenceCurrentMaxVal(fluorescenceShortStrings.length)
                break
            case "polish":
                setPolish([0, polishShortStrings.length])
                setPolishCurrentMinVal(0)
                setPolishCurrentMaxVal(polishShortStrings.length)
                break
            case "symmetry":
                setSymmetry([0, symmetryShortStrings.length])
                setSymmetryCurrentMinVal(0)
                setSymmetryCurrentMaxVal(symmetryShortStrings.length)
                break
            case "lWRatio":
                setLWRatio(lWMaxims)
                setLWRatioCurrentMinVal(initialLWRatio[0])
                setLWRatioCurrentMaxVal(initialLWRatio[1])
                break
            case "table":
                setTable(tableMaxims)
                setTableCurrentMinVal(initialTable[0])
                setTableCurrentMaxVal(initialTable[1])
                break
            case "depth":
                setDepth(depthMaxims)
                setDepthCurrentMinVal(initialDepth[0])
                setDepthCurrentMaxVal(initialDepth[1])
                break
            case "report":
                setReport(selectedReports.filter(item => item !== value[1]))
                break
            case "reset-all":
                setDiamondShape([])
                setCarat(caratMaxims)
                setCaratsCurrentMinVal(initialCarat[0])
                setCaratsCurrentMaxVal(initialCarat[1])
                setPrice(priceMaxims)
                setPriceCurrentMinVal(initialPrice[0])
                setPriceCurrentMaxVal(initialPrice[1])
                setCut([0, cutValueShortStrings.length])
                setCutCurrentMinVal(0)
                setCutCurrentMaxVal(cutValueShortStrings.length)
                setColor([0, colorValues.length])
                setColorCurrentMinVal(0)
                setColorCurrentMaxVal(colorValues.length)
                setClarity([0, clarityValues.length])
                setClarityCurrentMinVal(0)
                setClarityCurrentMaxVal(clarityValues.length)
                setFluorescence([0, fluorescenceShortStrings.length])
                setFluorescenceCurrentMinVal(0)
                setFluorescenceCurrentMaxVal(fluorescenceShortStrings.length)
                setPolish([0, polishShortStrings.length])
                setPolishCurrentMinVal(0)
                setPolishCurrentMaxVal(polishShortStrings.length)
                setSymmetry([0, symmetryShortStrings.length])
                setSymmetryCurrentMinVal(0)
                setSymmetryCurrentMaxVal(symmetryShortStrings.length)
                setLWRatio(lWMaxims)
                setLWRatioCurrentMinVal(initialLWRatio[0])
                setLWRatioCurrentMaxVal(initialLWRatio[1])
                setTable(tableMaxims)
                setDepth(depthMaxims)
                setDepthCurrentMinVal(initialDepth[0])
                setDepthCurrentMaxVal(initialDepth[1])
                setTableCurrentMinVal(initialTable[0])
                setTableCurrentMaxVal(initialTable[1])
                setReport([])
                break
            default:
                console.error("ERROR IN FILTER")
                break
        }
    }

    const toggleDiamondShape = (item: string) => {

        if (selectedDiamondShapes.includes(item)) {
            setDiamondShape(items => items.filter(el => el !== item))
        }
        else
            setDiamondShape(items => [...items, item])
    };

    const setDualStateValue = (value: number, isMin: boolean, category: string): void => {
        switch (category) {

            case "carat":
                if (isMin)
                    setCarat([value, selectedCarat[1]])
                else
                    setCarat([selectedCarat[0], value])
                break
            case "price":
                if (isMin)
                    setPrice([value, selectedPrice[1]])
                else
                    setPrice([selectedPrice[0], value])
                break
            case "cut":
                if (isMin)
                    setCut([value, selectedCut[1]])
                else
                    setCut([selectedCut[0], value])
                break
            case "color":
                if (isMin)
                    setColor([value, selectedColor[1]])
                else
                    setColor([selectedColor[0], value])
                break
            case "clarity":
                if (isMin)
                    setClarity([value, selectedClarity[1]])
                else
                    setClarity([selectedClarity[0], value])
                break
            case "lWRatio":
                if (isMin)
                    setLWRatio([value, selectedLWRatio[1]])
                else
                    setLWRatio([selectedLWRatio[0], value])
                break
            case "fluorescence":
                if (isMin)
                    setFluorescence([value, selectedFluorescence[1]])
                else
                    setFluorescence([selectedFluorescence[0], value])
                break
            case "polish":
                if (isMin)
                    setPolish([value, selectedPolish[1]])
                else
                    setPolish([selectedPolish[0], value])
                break
            case "symmetry":
                if (isMin)
                    setSymmetry([value, selectedSymmetry[1]])
                else
                    setSymmetry([selectedSymmetry[0], value])
                break
            case "table":
                if (isMin)
                    setTable([value, selectedTable[1]])
                else
                    setTable([selectedTable[0], value])
                break
            case "depth":
                if (isMin)
                    setDepth([value, selectedDepth[1]])
                else
                    setDepth([selectedDepth[0], value])
                break
            default:
                console.error("ERROR IN FILTER")
                break
        }
    }

    const setReports = (event: any) => {
        if (selectedReports.includes(event.target.value))
            setReport((prevItems) => prevItems.filter((item) => item !== event.target.value))
        else
            setReport([...selectedReports, event.target.value])
    }

    const insertStringArrayIntoFilter = (initArray: string[], currentArray: number[]) => {
        return (initArray.length !== currentArray[1] || currentArray[0] !== 0) ? initArray.slice(currentArray[0], currentArray[1]) : []
    }

    const insertNumberArrayIntoFilter = (initArray: number[], currentArray: number[]) => {
        return JSON.stringify(initArray) !== JSON.stringify(currentArray) ? currentArray : []
    }

    const OpenFilter = () => {
        if (FilterElement.current)
            FilterElement.current.style.transform = "translate3d(0, 0, 0)"
    }


    return (
        <>
            <div ref={FilterElement} className="diamond-search-hybrid__filter" >

                {/* FILTER */}
                <SelectedFilters
                    diamondShapes={selectedDiamondShapes}
                    carat={insertNumberArrayIntoFilter(caratMaxims, selectedCarat)}
                    price={insertNumberArrayIntoFilter(priceMaxims, selectedPrice)}
                    cut={insertStringArrayIntoFilter(cutValueStrings, selectedCut)}
                    color={insertStringArrayIntoFilter(colorValues, selectedColor)}
                    clarity={insertStringArrayIntoFilter(clarityValues, selectedClarity)}
                    lWRatio={JSON.stringify(initialLWRatio) !== JSON.stringify(selectedLWRatio) ? selectedLWRatio : []}
                    fluorescence={insertStringArrayIntoFilter(fluorescenceStrings, selectedFluorescence)}
                    polish={insertStringArrayIntoFilter(polishShortStrings, selectedPolish)}
                    symmetry={insertStringArrayIntoFilter(symmetryStrings, selectedSymmetry)}
                    table={insertNumberArrayIntoFilter(tableMaxims, selectedTable)}
                    depth={insertNumberArrayIntoFilter(depthMaxims, selectedDepth)}
                    report={selectedReports}
                    delivery='test'
                    resetFunction={filterReset}
                />

                {/* FILTER FIELDS */}
                <div className="filterScrollbar pb-30" id="js_filter_scrollbar">
                    <div className="widget-filter-shadow">

                        {/* DIAMOND ORIGIN */}
                        <Collapsible
                            headline='Diamond Origin'
                            open={true}>
                            <div className="widget-filter__cont in" id="Diamond-Origin">
                                <div className="mb-20">
                                    <ul className="tab-diamond-origin list-unstyled mb0" id="js_diamond_category">
                                        <li className={selectedNatural ? "active" : ""}>
                                            <a onClick={() => setNatural(true)}>
                                                Natural Diamonds
                                            </a>
                                        </li>
                                        <li className={selectedNatural ? "" : "active"}>
                                            <a onClick={() => setNatural(false)}>
                                                Lab Grown Diamonds
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Collapsible>

                        {/* DIAMOND SHAPE */}
                        <Collapsible
                            headline='Diamond Shape'
                            open={true}>
                            <div className="widget-filter__cont in" id="DiamondShape">

                                <ul id="js_shape" className="rose-slick-mobile rose-diamond-shape mini mb-25" data-fname="shape" data-gtype="radio">
                                    {selectableDiamondShapes.map((el, index) => (
                                        <DiamondFilterItem
                                            diamondShapes={selectedDiamondShapes}
                                            shape={el}
                                            shapeUrl={diamondImageUrls[index]}
                                            toggleDiamondShape={() => toggleDiamondShape(el)} />
                                    )
                                    )}
                                </ul>
                                <div className="clear-both"></div>
                            </div>
                        </Collapsible>

                        {/* selectedCarat */}
                        <Collapsible
                            headline='Carat'
                            open={true}>
                            <div style={{ paddingLeft: 8, paddingRight: 8 }} className="widget-filter__cont in" id="carat">
                                <div className="d-flex mb-25">
                                    <RangeSlider2
                                        sliderMinValue={caratMaxims[0]}
                                        sliderMaxValue={caratMaxims[1]}
                                        minVal={selectedCarat[0]}
                                        maxVal={selectedCarat[1]}
                                        setDualStateValue={setDualStateValue}
                                        category={"carat"}
                                        isCarat={true}
                                        setStateCurrentMaxVal={setCaratsCurrentMaxVal}
                                        setStateCurrentMinVal={setCaratsCurrentMinVal}
                                        stateMaxCurrentValue={caratsMaxCurrentValue}
                                        stateMinCurrentValue={caratsMinCurrentValue}
                                    />
                                    <div className="w35 text-right">
                                        <span>
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4" ><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg> */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>

                        {/* DIAMOND Price */}
                        <Collapsible
                            headline='Diamond Price'
                            open={true}>
                            <div style={{ paddingLeft: 8, paddingRight: 8 }} className="widget-filter__cont in" id="DiamondPrice">
                                <div className="d-flex mb-25">

                                    <RangeSlider2
                                        sliderMinValue={priceMaxims[0]}
                                        sliderMaxValue={priceMaxims[1]}
                                        minVal={selectedPrice[0]}
                                        maxVal={selectedPrice[1]}
                                        setDualStateValue={setDualStateValue}
                                        category={"price"}
                                        isPrice={true}
                                        setStateCurrentMaxVal={setPriceCurrentMaxVal}
                                        setStateCurrentMinVal={setPriceCurrentMinVal}
                                        stateMaxCurrentValue={priceMaxCurrentValue}
                                        stateMinCurrentValue={priceMinCurrentValue}
                                    />
                                    <div className="w35 text-right"><span>{/* <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg> */}</span></div>
                                </div>
                            </div>
                        </Collapsible>

                        {/* WHAT ARE YOU LOOKING FOR */}
                        <Collapsible
                            headline='What are you looking for?'
                            open={true}>
                            <div className="widget-filter__cont in" id="looking-suggestion">
                                <div className="mb-20">
                                    <ul className="tab-looking-suggestion list-unstyled mb0" id="js_simple_filter">
                                        <li onClick={handleSpecialOptionClick} style={{ border: "solid 1px #1B179E", background: selectedSpecialOption === "Most Sparkle" ? "#1B179E" : "", color: selectedSpecialOption === "Most Sparkle" ? "white" : "" }} data-value="Sparkle">Most Sparkle</li>
                                        <li onClick={handleSpecialOptionClick} style={{ border: "solid 1px #1B179E", background: selectedSpecialOption === "Best Balance" ? "#1B179E" : "", color: selectedSpecialOption === "Best Balance" ? "white" : "" }} data-value="Balance">Best Balance</li>
                                        <li onClick={handleSpecialOptionClick} style={{ border: "solid 1px #1B179E", background: selectedSpecialOption === "Top Quality" ? "#1B179E" : "", color: selectedSpecialOption === "Top Quality" ? "white" : "" }} data-value="Quality">Top Quality</li>
                                    </ul>
                                    {/*  <p id="js_simple_filter_prompt_text" className="fs-12 text-80 mt5">
                                        <span data-value="Sparkle" className="hidden">Super ideal cuts that create maximum shine.</span>
                                        <span data-value="Balance" className="hidden">Near colorless diamonds free of inclusions visible to the naked eye.</span>
                                        <span data-value="Quality" className="hidden">Exquisitely cut colorless diamonds with no visible inclusions.</span>
                                    </p> */}
                                </div>
                            </div>
                        </Collapsible>

                        {/* selectedCut */}
                        <Collapsible
                            headline='Cut'
                            open={true}>
                            <div style={{ paddingLeft: 8, paddingRight: 8 }} className="widget-filter__cont in" id="cut">
                                <div className="d-flex mb-10">

                                    <RangeSlider2
                                        sliderMinValue={0}
                                        sliderMaxValue={cutValueShortStrings.length}
                                        minVal={selectedCut[0]}
                                        maxVal={selectedCut[1]}
                                        setDualStateValue={setDualStateValue}
                                        category={"cut"}
                                        isString={true}
                                        stringValues={cutValueStrings}
                                        gap={1}
                                        setStateCurrentMaxVal={setCutCurrentMaxVal}
                                        setStateCurrentMinVal={setCutCurrentMinVal}
                                        stateMaxCurrentValue={cutMaxCurrentValue}
                                        stateMinCurrentValue={cutMinCurrentValue}
                                    />

                                    <div className="w35 text-right">
                                        <span>
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg> */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>

                        {/* selectedColor */}
                        <Collapsible
                            headline={'Color'}
                            open={true}>
                            <div className="widget-filter__cont in" id="color">
                                <div style={{ paddingLeft: 8, paddingRight: 8 }} className="d-flex mb-10">

                                    <RangeSlider2
                                        sliderMinValue={0}
                                        sliderMaxValue={colorValues.length}
                                        minVal={selectedColor[0]}
                                        maxVal={selectedColor[1]}
                                        setDualStateValue={setDualStateValue}
                                        category={"color"}
                                        isString={true}
                                        stringValues={colorValues}
                                        gap={1}
                                        setStateCurrentMaxVal={setColorCurrentMaxVal}
                                        setStateCurrentMinVal={setColorCurrentMinVal}
                                        stateMaxCurrentValue={colorMaxCurrentValue}
                                        stateMinCurrentValue={colorMinCurrentValue}
                                    />
                                    <div className="w35 text-right">
                                        <span  >
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg> */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>

                        {/* selectedClarity */}
                        <Collapsible
                            headline='Clarity'
                            open={true}>
                            <div style={{ paddingLeft: 8, paddingRight: 8 }} className="widget-filter__cont in" id="clarity">
                                <div className="d-flex mb-10">

                                    <RangeSlider2
                                        sliderMinValue={0}
                                        sliderMaxValue={clarityValues.length}
                                        minVal={selectedClarity[0]}
                                        maxVal={selectedClarity[1]}
                                        setDualStateValue={setDualStateValue}
                                        category={"clarity"}
                                        isString={true}
                                        stringValues={clarityValues}
                                        gap={1}
                                        setStateCurrentMaxVal={setClarityCurrentMaxVal}
                                        setStateCurrentMinVal={setClarityCurrentMinVal}
                                        stateMaxCurrentValue={clarityMaxCurrentValue}
                                        stateMinCurrentValue={clarityMinCurrentValue}
                                    />

                                    <div className="w35 text-right">
                                        <span  >
                                            {/*  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg> */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>

                        {/* OUR DIAMOND COLLECTION */}
                        {/*  <Collapsible
                                    headline='Our Diamond Collections ERROR'
                                    open={true}>
                                    <div className="widget-filter__cont in" id="OurDiamondCollections">
                                        <p className="fs-12 mb0">A curated assortment of our most innovative diamonds</p>
                                        <div className="checkbox checkbox-ty7-green rose-match-set" id="js_flawless_collection">
                                            <label className="filter-header"><i className="icons-checkbox"></i><span>Flawless Collection</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                            </label>
                                            <input aria-label="Origin Recycled" id="no-ms" value="false" className="hidden" data-text="Flawless Collection" />
                                        </div>

                                        <div className="checkbox checkbox-ty7-green rose-match-set js_collection">
                                            <label className="filter-header"><i className="icons-checkbox"></i><span>Carbon Capture</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                            </label>
                                            <input aria-label="Origin Recycled" id="no-ms" value="carbon capture" className="hidden" data-text="Carbon Capture" />
                                        </div>
                                        <div className="checkbox checkbox-ty7-green rose-match-set js_collection">
                                            <label className="filter-header"><i className="icons-checkbox"></i><span>100% Renewable Energy</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                            </label>
                                            <input aria-label="Origin Recycled" id="no-ms" value="offsite wind solar 100 grown selectedCut polished" className="hidden" data-text="100% Renewable Energy" />
                                        </div>
                                        <div className="checkbox checkbox-ty7-green rose-match-set" id="js_scs_certified">
                                            <label className="filter-header"><i className="icons-checkbox"></i><span>Sustainably Rated</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                            </label>
                                            <input aria-label="Origin Recycled" id="no-ms" value="false" className="hidden" data-text="Sustainably Rated" />
                                        </div>

                                        <div className="checkbox checkbox-ty7-green mb20 pb0 rose-match-set" id="js_truly_brilliant">
                                            <label className="filter-header"><i className="icons-checkbox"></i><span>Truly Brilliant</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                            </label>
                                            <input aria-label="Origin Recycled" id="no-ms" value="false" className="hidden" data-text="Truly Brilliant" />
                                        </div>
                                    </div>
                                </Collapsible> */}

                        {/* L:W RATIO*/}
                        <Collapsible
                            headline='L:W Ratio'
                            open={false}>
                            <div className="widget-filter__cont " id="lWRatio">
                                <div style={{ paddingLeft: 8, paddingRight: 8 }} className="d-flex mb-25">

                                    <RangeSlider2
                                        sliderMinValue={initialLWRatio[0]}
                                        sliderMaxValue={initialLWRatio[1]}
                                        minVal={selectedLWRatio[0]}
                                        maxVal={selectedLWRatio[1]}
                                        setDualStateValue={setDualStateValue}
                                        category={"lWRatio"}
                                        isCarat={true}
                                        setStateCurrentMaxVal={setLWRatioCurrentMaxVal}
                                        setStateCurrentMinVal={setLWRatioCurrentMinVal}
                                        stateMaxCurrentValue={lWRatioMaxCurrentValue}
                                        stateMinCurrentValue={lWRatioMinCurrentValue}
                                    />

                                    <div className="w35 text-right">
                                        <span>
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg> */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>

                        {/* selectedFluorescence*/}
                        <Collapsible
                            headline='Fluorescence'
                            open={false}>
                            <div className="widget-filter__cont " id="fluorescence">
                                <div style={{ paddingLeft: 8, paddingRight: 8 }} className="d-flex mb-10">

                                    <RangeSlider2
                                        sliderMinValue={0}
                                        sliderMaxValue={fluorescenceShortStrings.length}
                                        minVal={selectedFluorescence[0]}
                                        maxVal={selectedFluorescence[1]}
                                        setDualStateValue={setDualStateValue}
                                        category={"fluorescence"}
                                        isString={true}
                                        stringValues={fluorescenceStrings}
                                        gap={1}
                                        setStateCurrentMaxVal={setFluorescenceCurrentMaxVal}
                                        setStateCurrentMinVal={setFluorescenceCurrentMinVal}
                                        stateMaxCurrentValue={fluorescenceMaxCurrentValue}
                                        stateMinCurrentValue={fluorescenceMinCurrentValue}
                                    />

                                    <div className="w35 text-right">
                                        <span>
                                            {/*  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg> */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>

                        {/* selectedPolish*/}
                        <Collapsible
                            headline='Polish'
                            open={false}>
                            <div className="widget-filter__cont " id="polish">
                                <div style={{ paddingLeft: 8, paddingRight: 8 }} className="d-flex mb-10">

                                    <RangeSlider2
                                        sliderMinValue={0}
                                        sliderMaxValue={polishShortStrings.length}
                                        minVal={selectedPolish[0]}
                                        maxVal={selectedPolish[1]}
                                        setDualStateValue={setDualStateValue}
                                        category={"polish"}
                                        isString={true}
                                        stringValues={polishStrings}
                                        gap={1}
                                        setStateCurrentMaxVal={setPolishCurrentMaxVal}
                                        setStateCurrentMinVal={setPolishCurrentMinVal}
                                        stateMaxCurrentValue={polishMaxCurrentValue}
                                        stateMinCurrentValue={polishMinCurrentValue}
                                    />

                                    <div className="w35 text-right">
                                        <span>
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg> */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>

                        {/* selectedSymmetry*/}
                        <Collapsible
                            headline='Symmetry'
                            open={false}>
                            <div className="widget-filter__cont " id="symmetry">
                                <div style={{ paddingLeft: 8, paddingRight: 8 }} className="d-flex mb-10">

                                    <RangeSlider2
                                        sliderMinValue={0}
                                        sliderMaxValue={symmetryShortStrings.length}
                                        minVal={selectedSymmetry[0]}
                                        maxVal={selectedSymmetry[1]}
                                        setDualStateValue={setDualStateValue}
                                        category={"symmetry"}
                                        isString={true}
                                        stringValues={symmetryStrings}
                                        gap={1}
                                        setStateCurrentMaxVal={setSymmetryCurrentMaxVal}
                                        setStateCurrentMinVal={setSymmetryCurrentMinVal}
                                        stateMaxCurrentValue={symmetryMaxCurrentValue}
                                        stateMinCurrentValue={symmetryMinCurrentValue}
                                    />

                                    <div className="w35 text-right">
                                        <span >
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg> */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>

                        {/* selectedTable %*/}
                        <Collapsible
                            headline='Table %'
                            open={false}>
                            <div className="widget-filter__cont " id="table">
                                <div style={{ paddingLeft: 8, paddingRight: 8 }} className="d-flex mb-25">

                                    <RangeSlider2
                                        sliderMinValue={initialTable[0]}
                                        sliderMaxValue={initialTable[1]}
                                        minVal={selectedTable[0]}
                                        maxVal={selectedTable[1]}
                                        setDualStateValue={setDualStateValue}
                                        category={"table"}
                                        isProcent={true}
                                        setStateCurrentMaxVal={setTableCurrentMaxVal}
                                        setStateCurrentMinVal={setTableCurrentMinVal}
                                        stateMaxCurrentValue={tableMaxCurrentValue}
                                        stateMinCurrentValue={tableMinCurrentValue}
                                    />

                                    <div className="w35 text-right">
                                        <span  >
                                            {/*  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg> */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>

                        {/* selectedDepth %*/}
                        <Collapsible
                            headline='Depth %'
                            open={false}>
                            <div className="widget-filter__cont " id="depth">
                                <div style={{ paddingLeft: 8, paddingRight: 8 }} className="d-flex mb-25">

                                    <RangeSlider2
                                        sliderMinValue={initialDepth[0]}
                                        sliderMaxValue={initialDepth[1]}
                                        minVal={selectedDepth[0]}
                                        maxVal={selectedDepth[1]}
                                        setDualStateValue={setDualStateValue}
                                        category={"depth"}
                                        isProcent={true}
                                        setStateCurrentMaxVal={setDepthCurrentMaxVal}
                                        setStateCurrentMinVal={setDepthCurrentMinVal}
                                        stateMaxCurrentValue={depthMaxCurrentValue}
                                        stateMinCurrentValue={depthMinCurrentValue}
                                    />

                                    <div className="w35 text-right">
                                        <span >
                                            {/*  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg> */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>

                        {/* REPORT*/}
                        <Collapsible
                            headline='Report'
                            open={false}>

                            <CheckBoxes
                                reports={reportValues}
                                setReport={setReports}
                                signedArray={selectedReports} />
                        </Collapsible>

                        {/* FEATURES*/}
                        {/* <Collapsible
                                    headline='Features ERROR'
                                    open={false}>
                                    <div className="widget-filter__cont " id="Features">
                                        <div className="d-flex mb5">
                                            <div className="flex-fill">
                                                <div className="checkbox checkbox-ty7-green rose-match-set disabled" id="js_heart_and_arrow" data-exclude_quick_ship_suppliers="" data-filter-name="hearts_and_arrows_diamonds">
                                                    <label>
                                                        <i className="icons-checkbox"></i>
                                                        <span className="text">Hearts &amp; Arrows</span>
                                                        <span data-toggle="modal" data-target="#modal_hearts_and_arrows" data-mobile-access="ture">
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#b4b4b4"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                                        </span></label>
                                                    <input id="input_hearts_and_arrows_diamonds" data-text="Hearts &amp; Arrows" value="Yes" type="checkbox" aria-label="Hearts &amp; Arrows" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Collapsible> */}

                        {/* selectedDelivery - SPECIFIC DATE*/}
                        {/*    <Collapsible
                        headline='Delivery - Specific Date'
                        open={false}>
                        <div className="widget-filter__cont " id="delivery">
                            <div className="mb-15 fs-12" id="js_delivery_on">
                                <div className="radio-green-normal radio-green-cricle checkbox position-relative">
                                    <label data-value="0" ><i className="icons-checked"></i>Delivery On Or Before</label>
                                </div>

                                <div className="radio-green-normal radio-green-cricle checkbox position-relative">
                                    <label data-value="3" data-text="Wednesday, December 4" data-supplier-value="" ><i className="icons-checkbox"></i>Wednesday, December 4</label>
                                </div>

                                <div className="radio-green-normal radio-green-cricle checkbox position-relative">
                                    <label data-value="4" data-text="Thursday, December 5" data-supplier-value="Flawless Allure,Dholakia,Sky Diam,Amipi Lab,Z Diamond,Meylor Global NY,Kira NY,Shri Diam,Lab Grown Solitaire" ><i className="icons-checkbox"></i>Thursday, December 5</label>
                                </div>

                                <div className="radio-green-normal radio-green-cricle checkbox position-relative">
                                    <label data-value="5" data-text="Friday, December 6" data-supplier-value="Flawless Allure,Kiran NY,IGC NY,Dholakia,Sky Diam,Amipi Lab,Bhakti NY,Z Diamond,Emby International,Meylor Global NY,Kira NY,Shri Diam,Indian Diamonds,Lab Grown Solitaire,Diamond Lab" ><i className="icons-checkbox"></i>Friday, December 6</label>
                                </div>

                                <div className="radio-green-normal radio-green-cricle checkbox position-relative">
                                    <label data-value="6" data-text="Monday, December 9" data-supplier-value="Flawless Allure,Kiran NY,IGC NY,Dholakia,Sky Diam,Amipi Lab,Bhakti NY,Z Diamond,Rothschild,Emby International,Meylor Global NY,Kira NY,Shri Diam,Indian Diamonds,Lab Grown Solitaire,Diamond Lab" ><i className="icons-checkbox"></i>Monday, December 9</label>
                                </div>

                                <div className="radio-green-normal radio-green-cricle checkbox position-relative">
                                    <label data-value="7" data-text="Tuesday, December 10" data-supplier-value="Flawless Allure,Kiran NY,IGC NY,Dholakia,Sky Diam,Amipi Lab,Bhakti NY,Z Diamond,Rothschild,Emby International,Meylor Global NY,Kira NY,Shri Diam,Indian Diamonds,Lab Grown Solitaire,Diamond Lab" ><i className="icons-checkbox"></i>Tuesday, December 10</label>
                                </div>

                                <div className="radio-green-normal radio-green-cricle checkbox position-relative">
                                    <label data-value="8" data-text="Wednesday, December 11" data-supplier-value="Flawless Allure,Kiran NY,IGC NY,Dholakia,Sky Diam,Amipi Lab,Bhakti NY,Z Diamond,Rothschild,Emby International,Meylor Global NY,Kira NY,Shri Diam,Indian Diamonds,Lab Grown Solitaire,Diamond Lab" ><i className="icons-checkbox"></i>Wednesday, December 11</label>
                                </div>

                                <div className="radio-green-normal radio-green-cricle checkbox position-relative">
                                    <label data-value="9" data-text="Thursday, December 12" data-supplier-value="Flawless Allure,Kiran NY,IGC NY,Dholakia,Sky Diam,Amipi Lab,Bhakti NY,Z Diamond,Rothschild,Emby International,Meylor Global NY,Kira NY,Shri Diam,Indian Diamonds,Lab Grown Solitaire,Diamond Lab" ><i className="icons-checkbox"></i>Thursday, December 12</label>
                                </div>

                                <div className="radio-green-normal radio-green-cricle checkbox position-relative">
                                    <label data-value="999" data-text="Any Date" ><i className="icons-checkbox"></i>Any Date</label>
                                </div>
                            </div>
                        </div>
                    </Collapsible> */}

                    </div>
                </div >
                <div className="widget-filter__footer">
                    <button style={{ background: "#1B179E", color: "white" }} className="btn  btn-block btn-view-results" id="js_view_results">
                        View Results
                    </button>
                </div>
            </div >

            {viewPort !== "desktop" &&
                <div onClick={OpenFilter}
                    style={{
                        position: "fixed", bottom: "0px", zIndex: 9999, background: "white", width: "100vw", left: 0, textAlign: "center", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", border: "solid 1px black"
                    }}>
                    FILTER
                </div >}
        </>

    );
};

export default Filter