import React, { useState } from 'react';

type Video2Props = {
    image: string;
    video: string;
    ID: string;
    viewPort: string;
};

const Video2: React.FC<Video2Props> = ({ image, video, ID, viewPort }) => {
    const [isActive, setActive] = useState(false); // State to track if content is visible

    const hover = () => {
        if (viewPort === "desktop")
            setActive(true)
    }

    return (
        <div className="divSM-v360">
            <img src={image} alt="placeholder" className="img-responsive video_img" style={{ width: 264 }} />
            <div className="divSM divSM_34411573_diamonds_search_gallery" >
                <div className="dataMain position-relative add-video-bg" style={{ backgroundImage: "//image.brilliantearth.com/media/v360/imaged/2239329967/still.jpg?static_version=20241029" }}>
                    <div onMouseEnter={hover} className="v360main iframe-wrapper" style={{ width: "264px", height: "264px", position: "relative" }}>
                        {/* <img style={{
                                                                        width: "100%", zIndex: 0
                                                                    }} className="smimg" data-path="//image.brilliantearth.com/media/v360/" data-name="2239329967" alt="1.20 Carat Round Diamond" /> */}
                        {/* VIDEO HERE */}
                        {/* <canvas width="264" height="264"></canvas> */}
                        {isActive &&
                            <iframe className='iframe-video' src={video} style={{
                                width: "528px",
                                height: "528px",
                                transform: "translate3d(-265px, -265px, 0px)"
                            }} ></iframe>}
                        <div className='ID' style={{ display: "none" }}>{ID}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Video2