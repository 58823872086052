import React, { useState } from 'react';
import DiamondListCaret from './DiamondListCaret';

type DiamondListProps = {
    togglePriceSort: () => void,
    toggleCaratSort: () => void,
    toggleCutSort: () => void,
    toggleColorSort: () => void,
    toggleClaritySort: () => void,
    isCompareView: boolean,
    products: any[],
    compareProducts: any[],
    getShapeDiamond: (shape: string) => string,
    toggleCompare: (event: any, id: string) => void,
    sortingByAsc: ([string, boolean]),
    viewPort: string
};

const DiamondList: React.FC<DiamondListProps> = ({ togglePriceSort, toggleCaratSort, toggleCutSort, toggleColorSort, toggleClaritySort, isCompareView, products, compareProducts, getShapeDiamond, toggleCompare, sortingByAsc, viewPort }) => {

    console.log(sortingByAsc)

    return (

        <table style={{ width: "100%", border: "0", borderSpacing: "0", padding: "0" }} className="search-results-outline">
            <thead>
                {/* IMPLEMENT ANIMATION FOR ALL SVG WHEN STATE CHANGES */}
                <tr id="js_sort_by_header_table">
                    <th style={{ width: "8%" }} scope="col" className="pl10">
                        <span>
                            Shape</span>
                    </th>

                    <th scope="col" style={{ textAlign: "center", width: "8%", paddingTop: 7, paddingBottom: 7 }} className={sortingByAsc[0] === "price" ? "active" : ""}>
                        <span onClick={togglePriceSort}><a className="fw-500 avenir-heavy"  >Price
                            <DiamondListCaret
                                isOpen={sortingByAsc[0] === "price" ? sortingByAsc[1] : false}
                            />
                        </a></span>
                    </th>

                    <th scope="col" style={{ textAlign: "center", width: "8%", paddingTop: 7, paddingBottom: 7 }} className={sortingByAsc[0] === "carats" ? "active" : ""}>
                        <span onClick={toggleCaratSort}><a className="fw-500"  >Carat
                            <DiamondListCaret
                                isOpen={sortingByAsc[0] === "carats" ? sortingByAsc[1] : false}
                            />
                        </a></span>
                    </th>

                    <th scope="col" style={{ textAlign: "center", width: "8%", paddingTop: 7, paddingBottom: 7 }} className={sortingByAsc[0] === "cut" ? "active" : ""}>
                        <span onClick={toggleCutSort}><a className="fw-500" >Cut
                            <DiamondListCaret
                                isOpen={sortingByAsc[0] === "cut" ? sortingByAsc[1] : false}
                            />
                        </a></span>
                    </th>

                    <th scope="col" style={{ textAlign: "center", width: "8%", paddingTop: 7, paddingBottom: 7 }} className={sortingByAsc[0] === "color" ? "active" : ""}>
                        <span onClick={toggleColorSort}><a className="fw-500" >Color
                            <DiamondListCaret
                                isOpen={sortingByAsc[0] === "color" ? sortingByAsc[1] : false}
                            />
                        </a></span>
                    </th>
                    <th scope="col" style={{ textAlign: "center", width: "8%", paddingTop: 7, paddingBottom: 7 }} className={sortingByAsc[0] === "clarity" ? "active" : ""}>
                        <span onClick={toggleClaritySort}><a className="fw-500" >Clarity
                            <DiamondListCaret
                                isOpen={sortingByAsc[0] === "clarity" ? sortingByAsc[1] : false}
                            />
                        </a></span>
                    </th>
                    {/*  <th scope="col" style={{ textAlign: "center", width: "8%" }} className="width_ratio_hide">
                        <span onClick={toggleLWSort}><a className="fw-500" >L:W<svg style={{
                            width: 10,
                            height: 10,
                            marginLeft: 5
                        }} xmlns="http://www.w3.org/2000/svg" width="122" height="70" viewBox="0 0 122 70" fill="none"><path d="M2.60562 3.13226C4.01417 1.72386 5.92448 0.932643 7.91636 0.932642C9.90824 0.932642 11.8185 1.72386 13.2271 3.13226L61.0246 50.9274L108.822 3.13225C110.233 1.73525 112.139 0.953729 114.125 0.958596C116.11 0.963463 118.013 1.75431 119.417 3.15821C120.821 4.56211 121.612 6.46483 121.617 8.45029C121.622 10.4358 120.84 12.3424 119.444 13.7533L66.3359 66.8588C64.9274 68.2673 63.017 69.0586 61.0251 69.0586C59.0331 69.0586 57.1228 68.2673 55.7142 66.8588L2.6066 13.7533C1.90908 13.0561 1.35573 12.2282 0.978167 11.3171C0.600605 10.4059 0.406224 9.42932 0.406115 8.44304C0.406006 7.45676 0.600173 6.48012 0.977534 5.56889C1.35489 4.65766 1.90825 3.82969 2.60562 3.13226Z" fill="#2C2C2C"></path></svg></a></span>
                    </th> */}
                    {/* <th style={{ textAlign: "center", width: "4%" }} className="view-detail-col"></th> */}
                    <th style={{ textAlign: "center", width: "5%" }}>Compare</th>
                    <th style={{ width: "1%" }} scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {(isCompareView ? (products.filter(el => compareProducts.includes(el.ID.trim()))) : products).map((item: any, index) =>
                    <tr key={index} className="search-item" style={{ height: "50px", border: "none" }} >
                        <td className="shape 3" scope="col" style={{ textAlign: "left" }} >

                            <div style={{ textAlign: "left", marginLeft: "10px", display: "flex", gap: "5px" }}>

                                <img src={getShapeDiamond(item.shape)} style={{ width: "20px" }} alt="diamond shape" className="mr5" />
                                <span className="shape-type-name">{item.shape}</span>
                            </div>
                        </td>
                        <td scope="col" className="Price" style={{ visibility: "visible", width: "8%", textAlign: "center" }}>€{item.price}</td>
                        <td style={{ width: "8%", textAlign: "center" }} scope="col" className="Carat">{item.carats}</td>
                        <td style={{ width: "8%", textAlign: "center" }} scope="col" className="Cut">{item.cut}</td>
                        <td style={{ width: "8%", textAlign: "center" }} scope="col" className="Color">{item.color}</td>
                        <td style={{ width: "8%", textAlign: "center" }} scope="col" className="Clarity">{item.clarity}</td>
                        {/*  <td style={{ width: "8%", textAlign: "center" }} scope="col" className="LWRatio">{(parseFloat(item.length) / parseFloat(item.width)).toFixed(2)}</td> */}
                        < td className="un-bubble-ga ee_data compare_selector" style={{ width: "5%" }} scope="col">
                            <div style={{ justifyContent: "center" }} className="ml-auto d-flex js_compare_selector">
                                <div style={{ justifyContent: "center" }} className="thumbnail-checkbox">
                                    <div style={{ margin: 0 }} className="checkbox checkbox-ty4-green  js_select_compare">
                                        <label>
                                            <input checked={compareProducts.includes(item.ID.trim())} onChange={(event) => toggleCompare(event, item.ID)} style={{
                                                position: "static", width: 20,
                                                height: 20
                                            }} className="" type='checkbox' />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td style={{ width: "1%" }} scope="col">

                        </td>
                    </tr>
                )
                }
            </tbody>
        </table>
    );
};

export default DiamondList