import React, { useState, useEffect, useRef } from 'react';
import '../styles/test.css';



import Filter from './Filter';
import SvgButton from './SvgButton';
import DropDownFilter from './DropDownFilter';
import CompareViewSelector from './CompareViewSelector';
import DiamondGrid from './DiamondGrid';
import DiamondList from './DiamondList';


type DiamondSearchProps = {
    callback: () => void,
    viewPort: string
};

interface DiamondElement {
    ID: any,
    stock_id: any,
    shape: any,
    carats: any,
    selectedColor: any,
    selectedClarity: any,
    selectedCut: any,
    selectedPolish: any,
    selectedSymmetry: any,
    selectedFluorescence: any,
    length: any,
    width: any,
    selectedDepth: any,
    selectedTable: any, /* "%" */
    culet: any,
    report: any, /* IGI, GIA or HRD etc... */
    girdle: any,
    selectedPrice: any,
    video: any,
    image: any,
    origin: any,
    labgrown: any,

    /* stocknumber: string,
    gemstone: string,  ... everything you can get  
    height: string, */

    /*  certificate: string,  everything you can find  */
}

const DiamondSearch: React.FC<DiamondSearchProps> = ({ callback, viewPort }) => {
    let isLoading = useRef(false)
    let sentinel = useRef(null)
    let diamondItemOffset = useRef(1)

    const [activeFilter, setActiveFilter] = useState<string>("")

    const [sortingByAsc, setSortingBy] = useState<[string, boolean]>(["popular", true])

    const dropDownItems = ["Most Popular", "Price (Low to High)", "Price (High to Low)", "Carat (Low to High)", "Carat (High to Low)", "Cut (Low to High)", "Cut (High to Low)", "Color (Low to High)", "Color (High to Low)", "Clarity (Low to High)", "Clarity (High to Low)"/* , "Shape (A to Z)", "Shape (Z to A)" */]

    /* Products */
    const [products, setProducts] = useState<any[]>([]);
    const [compareProducts, setCompareProducts] = useState<any[]>([])

    const [isGridView, setGridView] = useState<boolean>(true)
    const [isCompareView, setCompareView] = useState<boolean>(false)

    const [diamondUrl, setDiamondUrl] = useState<string>("")

    /* const [diamondItemOffset, setDiamondItemOffset] = useState<number>(1) */

    useEffect(() => {
        if (diamondUrl !== "")
            startFetchDiamondItems(diamondUrl)

    }, [diamondUrl])

    useEffect(() => {
        if (products.length > 0)
            if (sentinel.current) {
                observer.observe(sentinel.current);
            }

        return () => {
            observer.disconnect();
        };
    }, [sentinel, products, isCompareView])

    /* useEffect(() => {
        if (sentinel.current) {
            observer.observe(sentinel.current);
        }
    }, [sentinel]) */


    const observer = new IntersectionObserver(entries => {
        const entry = entries[0];
        if (entry.isIntersecting && !isLoading.current) {
            console.log("is intersecting")
            GetMoreItems();
        }
    }, {
        /*  root: document.querySelector(".diamond-search-hybrid"), */
        rootMargin: '0px 0px 8000px 0px',
        threshold: 0,
    });

    const startFetchDiamondItems = async (url: string) => {
        const items = await fetchDiamondItems(url)
        if (items)
            setProducts(items)
    };

    const reFetchDiamondItems = async (url: string) => {
        if (!isCompareView) {
            const items = await fetchDiamondItems(url)

            setProducts(products.concat(items))
        }
    };

    const fetchDiamondItems = async (url: string): Promise<any[] | undefined> => {
        try {
            if (url !== "") {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data: any[] | undefined = await response.json(); // Type the response     

                return data

                /*  setProducts(products.concat(data)) */
            }
        } catch (err) {
            console.error(err instanceof Error ? err.message : 'An unknown error occurred');
            return []
        }
    };

    const dropDownFilterSelectHandler = (value: string) => {

        switch (value) {
            case dropDownItems[0]:
                setSortingBy(["popular", true])
                break
            case dropDownItems[1]:
                setSortingBy(["Price", true])
                break
            case dropDownItems[2]:
                setSortingBy(["Price", false])
                break
            case dropDownItems[3]:
                setSortingBy(["Carat", true])
                break
            case dropDownItems[4]:
                setSortingBy(["Carat", false])
                break
            case dropDownItems[5]:
                setSortingBy(["Cut", true])
                break
            case dropDownItems[6]:
                setSortingBy(["Cut", false])
                break
            case dropDownItems[7]:
                setSortingBy(["Color", true])
                break
            case dropDownItems[8]:
                setSortingBy(["Color", false])
                break
            case dropDownItems[9]:
                setSortingBy(["Clarity", true])
                break
            case dropDownItems[10]:
                setSortingBy(["Clarity", false])
                break
        }
    }

    const GetMoreItems = async () => {

        if (!isLoading.current) {
            isLoading.current = true
            if (diamondUrl !== "")
                await reFetchDiamondItems(diamondUrl + `&offset=${diamondItemOffset.current * 50}`)
            diamondItemOffset.current++
            isLoading.current = false
        }
        /* setDiamondItemOffset(diamondItemOffset + 1) */
    }

    //TODO ADD SORTS IN LISTVIEW
    const togglePriceSort = () => {
        if (sortingByAsc[0] === "price")
            setSortingBy(["price", !sortingByAsc[1]])
        else
            setSortingBy(["price", false])
    }

    const toggleCaratSort = () => {
        if (sortingByAsc[0] === "carats")
            setSortingBy(["carats", !sortingByAsc[1]])
        else
            setSortingBy(["carats", false])
    }

    const toggleCutSort = () => {
        if (sortingByAsc[0] === "cut")
            setSortingBy(["cut", !sortingByAsc[1]])
        else
            setSortingBy(["cut", false])
    }

    const toggleColorSort = () => {
        if (sortingByAsc[0] === "color")
            setSortingBy(["color", !sortingByAsc[1]])
        else
            setSortingBy(["color", false])
    }

    const toggleClaritySort = () => {
        if (sortingByAsc[0] === "clarity")
            setSortingBy(["clarity", !sortingByAsc[1]])
        else
            setSortingBy(["clarity", false])
    }

    const selectDiamond = (diamond: any) => {

        window.history.pushState(null, '', `#diamond=${diamond.ID}`);

        sessionStorage.setItem("diamond", JSON.stringify(diamond))

        callback()
    }

    const toggleCompare = (event: any, id: string) => {

        if (compareProducts.includes(id.trim()))
            setCompareProducts(compareProducts.filter(item => item !== id.trim()))
        else
            setCompareProducts([...compareProducts, id.trim()])
    }

    const getShapeDiamond = (shape: string): string => {
        switch (shape.toLowerCase()) {
            case "round":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/round.png"
            case "oval":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/oval.png"
            case "cushion":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/cushion.png"
            case "emerald":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/emerald.png"
            case "elongated cushion":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/elongated_cushion.png"
            case "pear":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/pear.png"
            case "radiant":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/radiant.png"
            case "princess":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/princess.png"
            case "marquise":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/marquise.png"
            case "asscher":
                return "//css.brilliantearth.com/static/img/diamond-search/diamond-shape/asscher.png"
            default:
                return "ERROR"
        }
    }

    /* TODO Add functions for Most Sparkle ... etc */

    return (
        <div id='diamond-search'>
            <div className='container container1260 ir317-diamond-search-results mt-30'>
                <div className='diamond-search-hybrid'>
                    <Filter
                        setProducts={setProducts}
                        sortingByAsc={sortingByAsc}
                        setUrl={setDiamondUrl}
                        viewPort={viewPort}
                    />

                    <div className='item-grid'>
                        <div className='item-grid-filter'>
                            <div className='inner'>
                                <div className='filter-type-wrapper'>

                                    <SvgButton
                                        callback={setGridView}
                                        isGridView={isGridView}
                                        name={"Grid"}
                                        forGrid={true}
                                    >
                                        <rect width="9" height="9" fill="#999999"></rect>
                                        <rect x="11" width="9" height="9" fill="#999999"></rect>
                                        <rect y="11" width="9" height="9" fill="#999999"></rect>
                                        <rect x="11" y="11" width="9" height="9" fill="#999999"></rect>
                                    </SvgButton>

                                    <SvgButton
                                        callback={setGridView}
                                        isGridView={isGridView}
                                        name={"List"}
                                        forGrid={false}
                                    >
                                        <rect width="20" height="5.33333" fill="#999999"></rect>
                                        <rect y="7.33325" width="20" height="5.33333" fill="#999999"></rect>
                                        <rect y="14.6667" width="20" height="5.33333" fill="#999999"></rect>
                                    </SvgButton>
                                </div>

                                <DropDownFilter
                                    dropDownFilterSelectHandler={dropDownFilterSelectHandler}
                                    dropDownItems={dropDownItems}
                                    viewPort={viewPort}
                                />

                            </div>

                            {viewPort === "desktop" && <CompareViewSelector
                                compareProducts={compareProducts}
                                isCompareView={isCompareView}
                                products={products}
                                setCompareView={setCompareView}
                            />}

                        </div>

                        {(isGridView ?
                            <DiamondGrid
                                compareProducts={compareProducts}
                                isCompareView={isCompareView}
                                products={products}
                                selectDiamond={selectDiamond}
                                toggleCompare={toggleCompare}
                                viewPort={viewPort}
                            />
                            :
                            <DiamondList
                                compareProducts={compareProducts}
                                getShapeDiamond={getShapeDiamond}
                                isCompareView={isCompareView}
                                products={products}
                                toggleCaratSort={toggleCaratSort}
                                toggleClaritySort={toggleClaritySort}
                                toggleColorSort={toggleColorSort}
                                toggleCompare={toggleCompare}
                                toggleCutSort={toggleCutSort}
                                togglePriceSort={togglePriceSort}
                                sortingByAsc={sortingByAsc}
                                viewPort={viewPort}
                            />
                        )}

                        <div style={{ width: 1, height: 1 }} ref={sentinel} id={"sentinel"}>

                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
export default DiamondSearch;