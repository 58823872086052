import React, { useState } from 'react';

import Video2 from './Video2';

type DiamondGridProps = {
    isCompareView: boolean,
    products: any[],
    compareProducts: any[],
    toggleCompare: (event: any, id: string) => void,
    selectDiamond: (diamond: any) => void,
    viewPort: string
};

const DiamondGrid: React.FC<DiamondGridProps> = ({ isCompareView, products, compareProducts, toggleCompare, selectDiamond, viewPort }) => {

    return (

        (<div style={{ marginBottom: 40 }} className={`real-item-grid ${viewPort === "mobile" && 'mobile'} ${viewPort === "tinyMobile" && 'tinyMobile'}`}>
            {(isCompareView ? (products.filter(el => compareProducts.includes(el.ID.trim()))) : products).map((item: any, index) =>
                <div key={`a${index}`} className="per-product line-loaded">
                    <div className="thumbnail ir364-search-results-thumbnail">
                        <div className="thumbnail-item">
                            <div className="thumbnail-imgs">
                                <div className="full-width thumbnail-badging">
                                    <div className="invert ir327-badge" style={{ backgroundColor: "#B4CFCF", color: "#183E40", display: "none" }}>
                                        <span>Sustainable</span>
                                    </div>
                                    <div className="ml-auto d-flex js_compare_selector">
                                        <div className="thumbnail-checkbox">
                                            <div style={{ margin: 0 }} className="checkbox checkbox-ty4-green  js_select_compare">
                                                <label>
                                                    <input checked={compareProducts.includes(item.ID.trim())} onChange={(event) => toggleCompare(event, item.ID)} style={{
                                                        position: "static"
                                                        , width: 20,
                                                        height: 20
                                                    }} className="" type='checkbox' />
                                                </label>
                                            </div></div>
                                    </div></div>
                                {/* SOMEWHERE HERE VIDEO */}
                                <div className="clk_through top_lg pdp_url element-to-stretch" draggable="false">
                                    {/*   <link rel="preload" href="" as="image" /> */}
                                    <div className="text-center">
                                        <Video2
                                            ID={item.ID}
                                            image={item.image}
                                            video={item.video}
                                            viewPort={viewPort}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="text-center thumbnail-caption">
                                <a className="avenir-medium thumbnail-name td-n2" >{item.carats} Carat {item.shape} Diamond</a>
                                <div className="mb5 thumbnail-4Cs">{item.cut} · {item.color} · {item.clarity}</div>
                                <div className="mb10 thumbnail-Price">€{item.price}</div>
                                <div onClick={() => selectDiamond(item)} className="thumbnail-link"><a className="btn btn-default2 btn-outline-dark display-block text-33 tt-n js-select-diamond-cta" >Select Diamond</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
        </div>)
    );
};

export default DiamondGrid