import React from 'react';

function Breadcrumb() {

    /* TODO make dynamic and add functionality*/
    return (
        <div id='Breadcrumb' className='container container1260 '>
            <ol className='inner'>
                <li>Home</li>
                <li>Engagement Rings</li>
                <li>Start with a Diamond</li> </ol >

        </div>

    );
}

export default Breadcrumb;