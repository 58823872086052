import React, { useState, useEffect } from 'react';

import WorkflowDiagram from "./components/WorkflowDiagram";
import DiamondSearch from "./components/DiamondSelector";
import Title from "./components/Title";
import Breadcrumb from "./components/Breadcrumb";
import './styles/app_styles.css';

function App() {
  const [show, setShow] = useState<boolean>(false);
  const [viewPort, setViewport] = useState<string>("desktop");

  useEffect(() => {

    function handleResize() {

      let width = window.innerWidth

      if (width < 350)
        //remove compare
        setViewport("tinyMobile")
      else if (width < 500)
        //remove compare
        setViewport("mobile")

      else if (width < 1020)
        //add filter popup
        setViewport("tablet")
      else
        setViewport("desktop")
    }

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const showApp = (value: boolean) => {
      setShow(true);
      const footer: HTMLElement | null = document.querySelector("elementor-location-footer")
      const main: HTMLElement | null = document.querySelector("elementor-location-single")

      if (main)
        main.style.display = "none"
      if (footer)
        footer.style.display = "none"
    }

    const handleMessage = (event: { origin: string; data: { action: string; }; }) => {
      console.log(event)
      if (event.origin !== 'https://everrings.com') return; // Replace with your WordPress site URL
      if (event.data.action === 'START_APP') {
        // Update state to show the app
        window.history.pushState(null, '', "#diamond-selector");
        showApp(true)
      }
    };

    const onPopState = () => {
      //TODO MAYBE REMOVE MAKE FASTER
      window.location.reload()
    }

    if (window.location.hash === "#diamond-selector") {
      showApp(true)
    }
    else
      window.addEventListener('message', handleMessage);

    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('message', handleMessage);
      window.removeEventListener('popstate', onPopState);
    };
  }, []);

  const onClickOnClose = () => {
    setShow(false)
    window.history.pushState(null, '', "#");
  }

  return (
    <div className="App" style={{
      position: "absolute", top: 0, background: "white", zIndex: 99999, width: "100%", left: 0
    }}>

      {show && <div>
        <Breadcrumb />

        <Title />

        {/* <WorkflowDiagram /> */}

        <DiamondSearch callback={() => setShow(false)} viewPort={viewPort} />

        <div onClick={onClickOnClose} style={{ position: "absolute", top: 10, right: 10 }} className='btn-close'>
          <span className="icon-cross"></span>
          <span className="visually-hidden">Close</span>
        </div>

        {/*  <div onClick={() => setShow(false)} style={{ position: "absolute", top: 0, right: 0 }} className='cross-stand-alone'></div> */}
      </div>}

    </div >

  );
}

export default App;
